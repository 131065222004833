import { watchImmediate } from '@vueuse/core'

export function useSyncTabAndUrl<
  T extends readonly string[],
  K extends T[number],
>(tabs: T, activeTab: Ref<K>, tabQueryKey = 'tab') {
  const router = useRouter()

  onBeforeMount(() => {
    const tabFromRoute = router.currentRoute.value.query[tabQueryKey]
    if (tabFromRoute && typeof tabFromRoute === 'string') {
      const matchedTab = tabs.find(
        (tab) => tab.toLowerCase() === tabFromRoute.toLowerCase(),
      )

      if (matchedTab) {
        activeTab.value = matchedTab as K
      }
    }
  })

  watchImmediate(activeTab, (newVal) => {
    if (newVal) {
      router.push({
        query: {
          ...router.currentRoute.value.query,
          [tabQueryKey]: newVal,
        },
      })
    }
  })
}
